import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

export default function Error() {
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>錯誤 | 開心按揭服務有限公司</title>
      </Helmet>
      <Navbar />
      <div className="content">
        <section>
          <h1>Page not found</h1>
          <p>The page you are looking for has been removed or restricted</p>
          <a href="javascript:history.back()">Go Back</a>
        </section>
      </div>
      <Footer />
    </div>
  )
}
